<template>
  <v-app>
    <div id="main">
      <div id="sidebar" v-if="!isLoginPage">
        <router-link to="/">
          <img src="@/assets/nabla_favicon.svg"/>
        </router-link>
      </div>
      <div id="container">
        <Navbar v-if="!isLoginPage" /> 
        <router-view  />
      </div>
    </div>
  </v-app>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  name: 'App',
  components: {
    Navbar: defineAsyncComponent(() => import(/* webpackChunkName: "Navbar" */ '../src/modules/shared/components/MainNavBar')),
  },
  computed: {
    isLoginPage() {
      return this.$route.path === '/login'
    }
  }
}
</script>

<style>
#app {
  font-family: Poppins, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background: #f5f5f5;

}
#main {
  display: flex;
}

#container {
  flex-grow: 1;
}
body {
  margin: 0;
}
div#main {
  display: flex;
  justify-content: space-between;
 
}
div#sidebar {
  width: 5.06vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 32.2999px 25.8399px rgba(0, 0, 0, 0.0417275), 0px 18.1071px 14.4856px rgba(0, 0, 0, 0.035), 0px 9.61652px 7.69322px rgba(0, 0, 0, 0.0282725), 0px 4.00165px 3.20132px rgba(0, 0, 0, 0.0196802);
  border-radius: 14.4607px;
  text-align: center;
}

div#sidebar img {
  position: relative;
  width: 60%;
  top: 30px;
}

div#sidebar router-link {
  display: block;
}

div#container {
  width: 91vw;
  background: #FFFFFF;
}

</style>

