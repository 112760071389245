export default{
    component: () => import(/* webpackChunkName: "alerts" */ '@/modules/alerts/layouts/AlertsLayout'),
    
    children: [
        {
            path: '/',
            redirect: '/alerts/all',
        },
        {
            path: '/alerts/all',
            name: 'All',
            component: () => import('../layouts/AllLayout'),
        },
        {
            path: '/alerts/pending',
            name: 'Pending',
            component: () => import('../layouts/PendingLayout'),
        },
        {
            path: '/alerts/solved',
            name: 'Solved',
            component: () => import('../layouts/SolvedLayout'),
        },

    ]
}