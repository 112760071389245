import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store'

import dashboardRouter from '../modules/dashboard/router'
import managementRouter from '../modules/management/router'
import alertsRouter from '../modules/alerts/router'


const routes = [
    {
        path: '/',
        name: 'Home',
        redirect: '/dashboard/portfolio',
    },

    {
        path: '/login',
        name: 'Login',
        component: () => import('../modules/Login'),
    },

    {
        path: '/dashboard',
        name: 'Dashboard',
        redirect: '/dashboard/portfolio',
        ...dashboardRouter
    },
    {
        path: '/management',
        name: 'Management',
        redirect: '/management/contracts',
        ...managementRouter
    },
    {
        path: '/status',
        name: 'Status',
        component: () => import('../modules/status/layouts/StatusLayout'),
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () => import('../modules/profile/layouts/ProfileLayout'),
    },
    {
        path: '/alerts/',
        name: 'Alerts',
        redirect: '/alerts/all',
        component: () => import('../modules/alerts/layouts/AllLayout'),
        ...alertsRouter
    },
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: () => import('../modules/404/NotFound'),
      },
    ]

    const router = createRouter({
        history: createWebHashHistory(),
        routes,
    })

    router.beforeEach((to, from, next) => {
        // Check if the user is logged in
        // Replace `isLoggedIn` with your actual method of checking if the user is logged in
        const isLoggedIn = store.getters.isLoggedIn;
      
        if (!isLoggedIn && to.path !== '/login') {
          // If the user is not logged in and the target route is not the login page,
          // redirect to the login page
          next('/login')
        } else {
          // Otherwise, proceed with the navigation
          next()
        }
      })
      

    export default router